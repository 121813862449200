<template>
  <mobile-screen :header="true" screen-class="levels-screen gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="level-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_levels-edit-qr-code-help-online',
          params: $route.params
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("level.edit-level.qr-code") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section" v-if="qrCode">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <div class="qr-code">
              <img :src="qrCode" alt="Qr code" />
            </div>
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav
        class="actions-menu theme-gray"
        v-if="selectedLevel && selectedLevel[0] && selectedLevel[0].qrcode"
      >
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn active"
              @click="regenerateQrCode(selectedLevel[0].id)"
            >
              {{ displayLabelName("level", "level", "regenerate-qr-code") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "EditLevelQrCode",
  mixins: [helpOnlineMixin],
  data() {
    return {
      helpSlug: "levels-qr-code"
    };
  },
  computed: {
    ...mapState("level", ["qrCode", "selectedLevel"])
  },
  mounted() {
    if (!this.selectedLevel && !this.selectedLevel[0]) {
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    }
  },
  methods: {
    async regenerateQrCode(id) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .post(`${apiEndpoints.company.levels}/${id}/qr-regenerate`)
        .then(() => {
          this.$store.dispatch("level/getLevel", id, { root: true });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
